import { DateTimeRangePickerValue } from '../../components/forms/DateTimeRangePicker/DateTimeRangePicker';
import { RootState } from '../state';
import { QueriesState } from './queries.model';
import { QueryType } from 'models/Queries';
import { QueryFilters, QueryFiltersValidation } from 'models/QueryFilters';
import { DcError } from 'models/Responses/DcErrorResponse';

const selectQueriesState = (state: RootState): QueriesState => state.queries;

export const getSelectedFilters =
  (type: QueryType) =>
  (state: RootState): QueryFilters =>
    selectQueriesState(state)[type].selectedFilters;

export const getAppliedFilters =
  (type: QueryType) =>
  (state: RootState): QueryFilters =>
    selectQueriesState(state)[type].appliedFilters;

const isFilterApplied = (value: Date | string | number | undefined | null): boolean =>
  value !== undefined && value !== null && value !== '';

export const hasFiltersApplied =
  (type: QueryType) =>
  (state: RootState): boolean =>
    Object.values(selectQueriesState(state)[type].appliedFilters).reduce<boolean>(
      (result, value) => result || isFilterApplied(value),
      false,
    );

export const getFiltersApplied =
  (type: QueryType) =>
  (state: RootState): Array<{ key: keyof QueryFilters; value: DateTimeRangePickerValue | string | number }> =>
    Object.entries(selectQueriesState(state)[type].appliedFilters).reduce<
      Array<{ key: keyof QueryFilters; value: DateTimeRangePickerValue | string | number }>
    >(
      (result, [key, value]) =>
        isFilterApplied(value) ? [...result, { key: key as keyof QueryFilters, value }] : result,
      [],
    );

export const getSelectedExchange =
  (type: QueryType) =>
  (state: RootState): string | undefined =>
    getSelectedFilters(type)(state).exchange;

export const getSelectedTradingSystem =
  (type: QueryType) =>
  (state: RootState): string | undefined =>
    getSelectedFilters(type)(state).tradingSystem;

export const getSelectedCustomer =
  (type: QueryType) =>
  (state: RootState): string | undefined =>
    getSelectedFilters(type)(state).customer;

export const getQueryFiltersValidation =
  (type: QueryType) =>
  (state: RootState): QueryFiltersValidation => {
    const filters = getSelectedFilters(type)(state);
    const isBusinessDatesRangeValid =
      filters.businessDates?.from !== undefined &&
      filters.businessDates?.to !== undefined &&
      filters.businessDates.from.getTime() <= filters.businessDates.to.getTime();
    return {
      account: 'VALID',
      businessDates: isBusinessDatesRangeValid ? 'VALID' : 'INVALID',
      customer: 'VALID',
      exchange: filters.exchange !== undefined ? 'VALID' : 'INVALID',
      exchangeOrderId: 'VALID',
      price: 'VALID',
      product: 'VALID',
      quantity: filters.quantity === undefined || filters.quantity > 0 ? 'VALID' : 'INVALID',
      side: 'VALID',
      functionalStatus: 'VALID',
      tradingSystem: 'VALID',
      customerSystemId: 'VALID',
      userSystemId: 'VALID',
      productDescription: 'VALID',
      timeInForce: 'VALID',
    };
  };

export const areQueryFiltersValid =
  (type: QueryType) =>
  (state: RootState): boolean => {
    const validations = getQueryFiltersValidation(type)(state);
    return Object.values(validations).every(v => v === 'VALID');
  };

export const getQueryPageSize =
  (type: QueryType) =>
  (state: RootState): number =>
    selectQueriesState(state)[type].pageSize ?? 0;

export const getQueryError =
  (type: QueryType) =>
  (state: RootState): DcError | undefined =>
    selectQueriesState(state)[type].queryError;

export const getQueryUrlParams =
  (type: QueryType) =>
  (state: RootState): string | undefined =>
    selectQueriesState(state)[type].urlParams;
