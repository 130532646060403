import { Actions } from '../actions';
import { EXECUTIONS_QUERY_REQUESTED } from '../executions/executions.actions';
import {
  QUERY_APPLIED_FILTERS_CLEARED,
  QUERY_PAGE_SIZE_CHANGED,
  QUERY_EXCHANGE_UPDATED,
  QUERY_ACCOUNT_UPDATED,
  QUERY_BUSINESS_DATE_RANGE_UPDATED,
  QUERY_CUSTOMER_UPDATED,
  QUERY_EXCHANGE_ORDER_ID_UPDATED,
  QUERY_PRODUCT_UPDATED,
  QUERY_QUANTITY_UPDATED,
  QUERY_SIDE_UPDATED,
  QUERY_FUNCTIONAL_STATUS_UPDATED,
  QUERY_TRADING_SYSTEM_UPDATED,
  QUERY_CUSTOMER_SYSTEM_UPDATED,
  QUERY_PRICE_UPDATED,
  QUERY_USER_SYSTEM_UPDATED,
  QUERY_PRODUCT_DESCRIPTION_UPDATED,
  QUERY_TIME_IN_FORCE_UPDATED,
  QUERY_EXCHANGE_FILTER_FAILED,
  QUERY_ERROR_RESET,
  QUERY_URL_PARAMS_UPDATED,
} from './queries.actions';
import { QueriesState, initialQueriesState } from './queries.model';
import { ORDERS_QUERY_REQUESTED } from 'store/orders/orders.actions';
import { POSITIONS_QUERY_REQUESTED } from 'store/positions/positions.actions';
import { parseTime, toMarketClose, toMarketOpen } from 'utils/dates/dates';
import { patchObject, patchObjectWithPatcher } from 'utils/objects/objects';

export const queriesReducer = (state: QueriesState = initialQueriesState, action: Actions): QueriesState => {
  switch (action.type) {
    case QUERY_ACCOUNT_UPDATED:
    case QUERY_BUSINESS_DATE_RANGE_UPDATED:
    case QUERY_EXCHANGE_ORDER_ID_UPDATED:
    case QUERY_PRODUCT_UPDATED:
    case QUERY_PRODUCT_DESCRIPTION_UPDATED:
    case QUERY_QUANTITY_UPDATED:
    case QUERY_SIDE_UPDATED:
    case QUERY_FUNCTIONAL_STATUS_UPDATED:
    case QUERY_PRICE_UPDATED:
    case QUERY_USER_SYSTEM_UPDATED:
    case QUERY_TIME_IN_FORCE_UPDATED:
      return patchObject(
        state,
        action.queryType,
        patchObject(
          state[action.queryType],
          'selectedFilters',
          patchObject(state[action.queryType].selectedFilters, action.filterType, action.value),
        ),
      );
    case QUERY_CUSTOMER_UPDATED:
      return patchObject(
        state,
        action.queryType,
        patchObject(
          state[action.queryType],
          'selectedFilters',
          patchObjectWithPatcher(state[action.queryType].selectedFilters, _state => ({
            ..._state,
            [action.filterType]: action.value,
            tradingSystem: undefined,
            customerSystemId: undefined,
            userSystemId: undefined,
          })),
        ),
      );
    case QUERY_TRADING_SYSTEM_UPDATED:
      return patchObject(
        state,
        action.queryType,
        patchObject(
          state[action.queryType],
          'selectedFilters',
          patchObjectWithPatcher(state[action.queryType].selectedFilters, _state => ({
            ..._state,
            [action.filterType]: action.value,
            customerSystemId: undefined,
            userSystemId: undefined,
          })),
        ),
      );
    case QUERY_CUSTOMER_SYSTEM_UPDATED:
      return patchObject(
        state,
        action.queryType,
        patchObject(
          state[action.queryType],
          'selectedFilters',
          patchObjectWithPatcher(state[action.queryType].selectedFilters, _state => ({
            ..._state,
            [action.filterType]: action.value,
            userSystemId: undefined,
          })),
        ),
      );
    case QUERY_EXCHANGE_UPDATED:
      return patchObject(
        state,
        action.queryType,
        patchObject(state[action.queryType], 'selectedFilters', {
          exchange: action.value,
          businessDates: {
            from: action.exchangeInfo?.startOfDay
              ? toMarketOpen(parseTime(action.exchangeInfo.startOfDay), action.exchangeInfo?.timeZone)
              : toMarketOpen(parseTime('06:00:00.000'), action.exchangeInfo?.timeZone),
            to: action.exchangeInfo?.endOfDay
              ? toMarketClose(parseTime(action.exchangeInfo.endOfDay), 1, action.exchangeInfo?.timeZone)
              : toMarketClose(parseTime('05:59:59.999'), 1, action.exchangeInfo?.timeZone),
          },
        }),
      );
    case QUERY_APPLIED_FILTERS_CLEARED:
      return patchObject(
        state,
        action.queryType,
        patchObject(state[action.queryType], 'selectedFilters', {
          businessDates: state[action.queryType].selectedFilters.businessDates,
          exchange: state[action.queryType].selectedFilters.exchange,
        }),
      );
    case ORDERS_QUERY_REQUESTED:
      return patchObject(state, 'orders', patchObject(state.orders, 'appliedFilters', action.filters));
    case EXECUTIONS_QUERY_REQUESTED:
      return patchObject(state, 'executions', patchObject(state.executions, 'appliedFilters', action.filters));
    case POSITIONS_QUERY_REQUESTED:
      return patchObject(state, 'positions', patchObject(state.positions, 'appliedFilters', action.filters));
    case QUERY_PAGE_SIZE_CHANGED:
      return patchObject(state, action.queryType, patchObject(state[action.queryType], 'pageSize', action.size));
    case QUERY_EXCHANGE_FILTER_FAILED:
      return patchObject(state, action.queryType, {
        ...state[action.queryType],
        queryError: action.error,
        urlParams: undefined,
      });
    case QUERY_ERROR_RESET:
      return patchObject(state, action.queryType, patchObject(state[action.queryType], 'queryError', undefined));
    case QUERY_URL_PARAMS_UPDATED:
      return patchObject(state, action.queryType, patchObject(state[action.queryType], 'urlParams', action.urlParams));
    default:
      return state;
  }
};
